<template>
  <div class="echart">
    <div class="echart-top">
      <div class="echart-title">月度-测评数据</div>
      <div class="title-search">
        <el-time-picker
          is-range
          v-model="times"
          size="mini"
          format="HH:mm"
          width="120"
          range-separator="~"
          start-placeholder="开始"
          end-placeholder="结束"
          class="date-li"
          placeholder="时间">
        </el-time-picker>
        <select name="" id="" class="sel-div" >
          <option value="" class="vat-option">增值税专用发票</option>
          <option value="" class="vat-option">增值税普通发票</option>
          <option value="" class="vat-option">增值税电子发票</option>
        </select>
      </div>
    </div>
    <div class="echart-box" ref="box"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data () {
    return {
      times: '',
      mycart: null
    }
  },
  mounted(){
    this.setEcharts()
    
  },
  methods:{
    setEcharts() {
      const that = this
      const userdom = this.$refs.box
      this.mycart = this.$echarts.init(userdom)
      window.addEventListener('resize', () => {
        this.mycart.resize();
      });
      const option = {
        grid:{
          top:'50',
          left: '0',
          right: '0',
          bottom: '50'
        },
        tooltip: {
          trigger: 'axis',
          // formatter: function (params) {
          //   console.log("--x轴类目对应的参数数组--", params); // 比如当鼠标hover到孙悟空同学这一列的时候，这个params数组存放的每一项数据分别是语数外三门成绩的具体信息
          //   var res = // 字符串形式的html标签会被echarts转换渲染成数据，这个res主要是画的tooltip里的上部分的标题部分
          //     "<div style='margin-bottom:5px;padding:0 12px;width:100%;height:24px;line-height:24px;background:pink;border-radius:3px;'><p>" +
          //     params[0].name +
          //     " </p></div>";
          //   for (var i = 0; i < params.length; i++) {
          //     //因为是个数组，所以要遍历拿到里面的数据，并加入到tooltip的数据内容部分里面去
          //     res += `<div style="color: #fff;font-size: 14px; padding:0 12px;line-height: 24px">
          //         <span style="display:inline-block;margin-right:5px;border-radius:2px;width:10px;height:10px;background-color:${[
          //           params[i].color, // 默认是小圆点，我们将其修改成有圆角的正方形，这里用的是模板字符串。并拿到对应颜色、名字、数据
          //         ]};"></span>
          //         ${params[i].seriesName}
          //         ${params[i].data}分
          //       </div>`;
          //   }
          //   return res; // 经过这么一加工，最终返回出去并渲染，最终就出现了我们所看的效果
          // },
          formatter: function (params) {
            // 格式化提示框内容
            //var result = params[0].name + '<br/>';
            var result = ''
            params.forEach(function (item) {
                //result += item.seriesName + ' : ' + item.value + '<br/>';
                result +=  item.value + '<br/>';
            });
            return result;
        
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: ['22.02', '22.03', '22.04', '22.05', '22.06', '22.07', '22.08', '22.09', '22.10'],
          splitLine: {
            show: false
          }
        },
        yAxis: {
          splitLine: {
            show: false
          }
        },
        series: [
          {
            data: [100, 130, 320, 280, 220, 360, 120,230,220,245],
            type: 'line',
            smooth: true,
            showSymbol: false,
            color: 'rgba(0, 188, 212, 1)',
            markLine: {
              symbol: ['none', 'none'],
              label: { show: false },
              data: [
                { xAxis: 1,lineStyle: {color: "#CACACA",type: 'dashed' ,} }, 
                { xAxis: 2,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 3,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 4,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 5,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 6,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 7,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 8,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 9,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
                { xAxis: 10,lineStyle: {color: "#CACACA",type: 'dashed' ,} },
              ]
            },
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: 'rgb(0, 188, 212, 0.22)'
                },
                {
                  offset: 1,
                  color: 'rgb(0, 188, 212, 0)'
                }
              ])
            },
          }
        ]
      };
      this.mycart.setOption(option)
    }
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.mycart);
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.echart
  width 100%
  .echart-box
    width 100%
    height 260px
  .echart-top
    padding 0 40px
    display flex
    justify-content space-between
  .title-search
    .date-li
      width 130px!important
    .sel-div
      margin-left 40px
</style>