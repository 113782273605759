import { render, staticRenderFns } from "./FromDetail.vue?vue&type=template&id=5d8a0a22&scoped=true"
import script from "./FromDetail.vue?vue&type=script&lang=js"
export * from "./FromDetail.vue?vue&type=script&lang=js"
import style0 from "./FromDetail.vue?vue&type=style&index=0&id=5d8a0a22&prod&lang=stylus&rel=stylesheet%2Fstylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d8a0a22",
  null
  
)

export default component.exports