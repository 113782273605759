<template>
  <div class="static">
    <div class="evaluat">
      <div class="evaluat-left">
        <Echart1></Echart1>
      </div>
      <div class="evaluat-right">
        <Echart2></Echart2>
      </div>
    </div>
    
  </div>
</template>

<script>
import Echart1 from '@/components/EchartsList/Echart1'
import Echart2 from '@/components/EchartsList/Echart2'
export default {
  data () {
    return {
    }
    
  },
  components:{
    Echart1,
    Echart2
  },
  methods:{
    
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.static
  padding 40px
  .evaluat
    width 100%
    display flex
    .evaluat-left
      width 65%
      margin-right 40px
    .evaluat-right
      flex 1
</style>