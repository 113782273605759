<template>
  <div class="echart">
    <div class="echart-top">
      <div class="echart-title">部门评测</div>
    </div>
    <div class="echart-box" ref="box"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  data () {
    return {
      times: '',
      mycart: null
    }
  },
  mounted(){
    this.setEcharts()
    
  },
  methods:{
    setEcharts() {
      const that = this
      const userdom = this.$refs.box
      this.mycart = this.$echarts.init(userdom)
      window.addEventListener('resize', () => {
        this.mycart.resize();
      });
      const option = {
        grid:{
          top:'50',
          left: '0',
          right: '0',
          bottom: '50'
        },
        xAxis: {
          type: 'category',
          data: ['心理科', '体检科', '内科', '消化科', '精神科']
        },
        yAxis: {
          type: 'value'
        },
        
        series: [
          {
            data: [
              120,
              200,
              150,
              80,
              70,
            ],
            type: 'bar',
            barWidth: 30,
            itemStyle: {
              borderRadius: [8, 8, 8, 8], // 这里设置圆角的大小
              color: function (params) {
                console.log('params',params)
                // 这里可以根据需要设置不同的颜色，例如根据数据值
                return params.dataIndex%2 === 0 ? '#03C070' : '#EDB100';
              }
            }
          }
        ]
      };
      this.mycart.setOption(option)
    }
  },
  beforeDestroy(){
    window.removeEventListener('resize', this.mycart);
  }
}
</script>

<style lang="stylus" type="text/stylus" rel="stylesheet/stylus" scoped>
.echart
  width 100%
  .echart-box
    width 100%
    height 260px
  .echart-top
    padding 0 40px
    display flex
    justify-content space-between
    .echart-title
      font-size 18px
      font-weight bold
      color #1f1f1f
  .title-search
    .date-li
      width 130px!important
    .sel-div
      margin-left 40px
</style>