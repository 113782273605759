import { render, staticRenderFns } from "./ToAccess.vue?vue&type=template&id=279600a7&scoped=true"
import script from "./ToAccess.vue?vue&type=script&lang=js"
export * from "./ToAccess.vue?vue&type=script&lang=js"
import style0 from "./ToAccess.vue?vue&type=style&index=0&id=279600a7&prod&lang=stylus&rel=stylesheet%2Fstylus&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "279600a7",
  null
  
)

export default component.exports